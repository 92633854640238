import { camelCase } from "lodash";
import * as structuredTextTypes from "./structuredTextTypes";

export function formatPrismicObject(object) {
  return Object.keys(object).reduce((acc, prop) => {
    const flattenedField = flattenPrismicField(object[prop]);

    return flattenedField === null
      ? acc
      : Object.assign(acc, {
          [camelCase(prop)]: flattenedField,
        });
  }, {});
}

export const flattenPrismicField = (field) => {
  switch (field.type) {
    case "Link.web":
      return field.value.url;
    case "Image":
      return field.value.main;
    case "SliceZone":
      return formatSliceZone(field);
    case "StructuredText":
      return formatStructuredText(field);
    default:
      return formatValue(field.value);
  }
};

export const formatValue = (item) =>
  Array.isArray(item) ? item.map(formatPrismicObject) : item;

export const formatSliceZone = (sliceZone) =>
  sliceZone.value.map(({ slice_type, ...rest }) => {
    const formattedSlice = {
      type: slice_type,
    };

    if (!!rest["non-repeat"] && !!rest.repeat) {
      formattedSlice.data = formatPrismicObject(rest["non-repeat"]);
      formattedSlice.repeat =
        rest.repeat.length === 0 || Object.keys(rest.repeat[0]).length === 0
          ? []
          : rest.repeat.map(formatPrismicObject);
    } else {
      formattedSlice.value = flattenPrismicField(rest.value);
    }
    return formattedSlice;
  });

function isEmpty(field) {
  return (
    field.value.length === 1 &&
    field.value[0].text === "" &&
    field.value[0].type === "paragraph"
  );
}

export function formatStructuredText(field) {
  return isEmpty(field) ? null : field.value.map(formatStructuredTextParagraph);
}

function getChildWithTrimmedText({ child, newEnding }) {
  const previousChildTrimmedText = child.text.substring(0, newEnding);

  return previousChildTrimmedText === ""
    ? null
    : { ...child, text: previousChildTrimmedText };
}

export function formatStructuredTextParagraph({ text, spans, type, ...rest }) {
  const children = [];
  let lastEnd = 0;

  // Exception for '[button]' tags
  if (spans && text.includes("[button]")) {
    return {
      type: "button",
      label: text.replace("[button]", ""),
      link: spans[0].data.value.url,
    };
  }

  if (type === "image") {
    const { url: src, ...other } = rest;

    return { type: "image", src, other };
  }

  if (type === "embed") {
    return { type: "image", src: rest.oembed.embed_url };
  }

  spans.forEach((span, index) => {
    const { start, end } = span;

    // Add text from last end to the start of the new span
    if (lastEnd < start) {
      children.push(structuredTextTypes.span(text.slice(lastEnd, start)));
    }

    // Add formatted text if the type is defined
    if (typeof structuredTextTypes[span.type] === "function") {
      // same string
      if (lastEnd === end) {
        const previousSpanStart = index === 0 ? 0 : spans[index - 1].start;
        const previousChild = children.pop();
        const previousChildWithTrimmedText = getChildWithTrimmedText({
          child: previousChild,
          newEnding: span.start - previousSpanStart,
        });

        if (previousChildWithTrimmedText !== null) {
          children.push(previousChildWithTrimmedText);
        }

        const newChild = structuredTextTypes[span.type](
          text.slice(start, end),
          span,
        );

        children.push({
          ...newChild,
          type: previousChild.type.concat(newChild.type),
        });
      } else {
        children.push(
          structuredTextTypes[span.type](text.slice(start, end), span),
        );
      }
    }

    lastEnd = end;
  });

  // Add the remaining text to the end
  if (lastEnd < text.length) {
    children.push(structuredTextTypes.span(text.slice(lastEnd)));
  }

  const formattedObject = {
    type,
    children,
  };

  return formattedObject;
}
