import { getConfig } from "@nested/config";
import { addQueryParams } from "../request/request";

const { IMGIX_URI } = getConfig();

function autoFormat(url) {
  if (!url) return "";
  return addQueryParams(url, { auto: "format,compress" });
}

function hasDomain(url) {
  return url.includes(IMGIX_URI);
}

function addDomain(path) {
  return `${IMGIX_URI}/${path}`;
}

export function getImage(image) {
  const formattedImage = autoFormat(image);
  return hasDomain(formattedImage) ? formattedImage : addDomain(formattedImage);
}

export const createSourceSet = (source, width, height) =>
  [
    addQueryParams(source, { w: width, h: height }),
    `${addQueryParams(source, { w: width * 2, h: height * 2 })} 2x`,
  ].join(", ");
