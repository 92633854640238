import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const H6 = styled.h6`
  ${fontStyle({
    smallFontSize: "15px",
    smallLineHeight: "20px",
    mediumFontSize: "15px",
    mediumLineHeight: "20px",
  })};

  letter-spacing: 1.5px;
`;

H6.displayName = "H6";

// eslint-disable-next-line
export default H6;
