import { FETCH_PROPERTIES_SUCCESS, ADD_PRISMIC_REF } from "./constants";

export const initialState = {
  prismicRef: null,
  properties: [],
};

function AppReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTIES_SUCCESS:
      return { ...state, properties: action.payload.json };
    case ADD_PRISMIC_REF:
      return { ...state, prismicRef: action.payload.prismicRef };
    default:
      return state;
  }
}

export { AppReducer };
