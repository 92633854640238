import networkLayer from "./networkLayer";
import { configure } from "./configure";
import { getRef } from "./fetch";
import { fetchCmsInfoSuccess } from "./withCms/actions";

export { renderStructuredText, renderSlices } from "./render";
export { withCms, reducer } from "./withCms";
export { default as cmsSaga } from "./fetch";

const cms = {
  url: null,
  actionsPrefix: "nested/withCms",
  networkLayer,
};

cms.configure = configure.bind(cms);
cms.getRef = getRef.bind(null, cms);
cms.fetchCmsInfoSuccess = fetchCmsInfoSuccess.bind(null, cms);

// eslint-disable-next-line
export default cms;
