// @flow
import { Helmet } from "react-helmet";
import { getImage } from "@nested/utils";

export const COMPANY_PHONE = "020 3858 0695";
export const DEFAULT_TITLE = "The modern estate agent";

const MetaTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta property="og:site_name" content="Nested" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={DEFAULT_TITLE} />
    <meta property="og:image" content={getImage("nested_og_image.png")} />
    <meta
      property="google-site-verification"
      content="cQNo_uB81Y-9EQk0NPcI_G5S5vd6CqT4a_H5vyvPEmc"
    />
    <title>Nested - {DEFAULT_TITLE}</title>
  </Helmet>
);

export { MetaTags };
