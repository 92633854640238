// @flow
import { type Match } from "react-router";
import { type Saga } from "redux-saga";
import { select, call, put, getContext } from "redux-saga/effects";
import { PrismicResourceNotFound } from "../../utils/PrismicClient/PrismicClient";

const GET_POST_SUCCESS = "app/pages/blog/GET_POST_SUCCESS";
const GET_POST_ERROR = "app/pages/blog/GET_POST_ERROR";
const PAGE_NOT_FOUND = "app/pages/blog/PAGE_NOT_FOUND";

export type State = {
  [key: string]: RemoteContent<PrismicPost>,
};

type GetPostSuccessAction = {
  type: typeof GET_POST_SUCCESS,
  payload: {
    id: string,
    content: PrismicPost,
  },
};

type GetPostErrorAction = {
  type: typeof GET_POST_ERROR,
  payload: {
    id: string,
    error: Error,
  },
};

type Action = GetPostSuccessAction | GetPostErrorAction;

const getPostSuccess = (id: string, content: PrismicPost) => ({
  type: GET_POST_SUCCESS,
  payload: {
    id,
    content,
  },
});

const getPostError = (id: string, error: Error) => ({
  type: GET_POST_ERROR,
  payload: {
    id,
    error,
  },
});

export const pageNotFound = (id: string) => ({
  type: PAGE_NOT_FOUND,
  payload: {
    id,
  },
});

export const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case GET_POST_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          fetched: true,
          content: action.payload.content,
        },
      };
    case GET_POST_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          fetched: false,
          error: action.payload.error,
        },
      };
    case PAGE_NOT_FOUND:
      return {
        ...state,
        [action.payload.id]: {
          notFound: true,
          fetched: true,
        },
      };
    default:
      return state;
  }
};

const selectPost = ({ blog }, id) => blog.post[id];

export function* getBlogPostSaga({
  params: { id },
}: Match<{ id: string }>): Saga<void> {
  const post = yield select(selectPost, id);
  if (post?.fetched) {
    return;
  }

  try {
    const prismicClient = yield getContext("prismicClient");
    const result = yield call(prismicClient.getPost, id);
    yield put(getPostSuccess(id, result));
  } catch (e) {
    if (
      e instanceof PrismicResourceNotFound ||
      e.message === "Failed to fetch post" ||
      e.status === 404
    ) {
      yield put(pageNotFound(id));
    } else {
      yield put(getPostError(id, e));
    }
  }
}
