// @flow
const SET_HOME_VALUE = "app/slices/FeeCalculators/SET_HOME_VALUE";

type State = {
  homeValue: ?number,
};

type Action = SetHomeValueAction;

type SetHomeValueAction = {
  type: typeof SET_HOME_VALUE,
  payload: {
    homeValue: ?number,
  },
};

export const setHomeValue = (homeValue: ?number): SetHomeValueAction => ({
  type: SET_HOME_VALUE,
  payload: {
    homeValue,
  },
});

const getInitialState = (): State => ({
  homeValue: undefined,
});

export const reducer = (
  state: State = getInitialState(),
  action: Action,
): State => {
  switch (action.type) {
    case SET_HOME_VALUE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
