import styled from "styled-components";
import { media, ErrorMessage } from "@nested/ui";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 122px 10px;
  min-height: calc(75vh);

  ${media.tablet`
    padding: 110px 0px;
  `};
`;

const ServerError = () => (
  <Wrapper>
    <ErrorMessage
      icon="error.svg"
      title="Something appears to have gone wrong"
      message="One of our team has been alerted and they'll be working on a fix soon. Please try refreshing the page to see if that fixes the problem"
    />
  </Wrapper>
);

export { ServerError };

export const UniversalRender = ServerError;
