import { css } from "styled-components";

/*
 * NOTE: These styles are explicitly not mapped to h1, h2 etc. This is because
 * depending on the context, we may want our h1 to be XL or L - this could be
 * due to the different presentation styles between different pages/slices, or
 * merely the difference between mobile and desktop
 */

/*
 * ANOTHER NOTE: These font sizes and line heights may appear different to the
 * values in Marvel, but Marvel is wrong and these are the de facto sizes confirmed
 * by Will. Don't change them.
 */

const extraLargeHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 56px;
  line-height: 62px;
`;

const largeHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
`;

const mediumHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
`;

const regularHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
`;

const smallHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

const sectionHeading = css`
  color: ${({ theme }) => theme.palette.hague80};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

const largeSectionHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
`;

export {
  extraLargeHeading,
  largeHeading,
  mediumHeading,
  regularHeading,
  smallHeading,
  sectionHeading,
  largeSectionHeading,
};
