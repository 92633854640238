// @flow
import cms from "@nested/react-with-cms";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";
import { getConfig } from "@nested/config";
import { PrismicClient } from "./utils/PrismicClient/PrismicClient";
import { backgroundSaga } from "./containers/App/sagas";
import { browserDataFetchSaga } from "./containers/App/locationDucks";
// Import all the third party stuff
import { configureStore } from "./store";
import { Main } from "./Main";

const { CMS_API } = getConfig();

/**
 * Axe is an automated accessibility (a11y) engine that we use to notify us of
 * any a11y failures on our website. Check the console whenever you visit a page
 * and you should see a list of things that need to be changed ranked in order
 * of importance.
 */
if (process.env.NODE_ENV !== "production") {
  const axe = require("react-axe"); // eslint-disable-line global-require
  axe(React, ReactDOM, 1000);
}

const history = createBrowserHistory();

// The initial state of the app can be set on the server
const initialState = window.APP_STATE || {};

const prismicClient = new PrismicClient();
const store = configureStore({ prismicClient, initialState, history });
store.runSaga(backgroundSaga);
store.runSaga(browserDataFetchSaga);

cms.configure({
  options: {
    url: CMS_API,
  },
});

function render(App) {
  const el = document.getElementById("app");
  if (!el) {
    throw new Error("Could not find container element");
  }
  ReactDOM.hydrate(
    <BrowserRouter>
      <CookiesProvider>
        <App store={store} history={history} />
      </CookiesProvider>
    </BrowserRouter>,
    el,
  );
}

render(Main);
