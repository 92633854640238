import styled, { css } from "styled-components";
import { fontStyle } from "../../../styles/text";

const P = styled.p`
  ${({ theme }) =>
    fontStyle({
      smallFontSize: "18px",
      smallLineHeight: "28px",
      mediumFontSize: "18px",
      mediumLineHeight: "28px",
      color: theme.colors.greys.default,
    })} ${({ error }) =>
    error
      ? css`
          color: ${({ theme }) => theme.colors.error.default};
        `
      : ""};

  b,
  strong {
    color: ${({ theme, error }) =>
      error ? theme.colors.error.default : theme.colors.greys.dark};
  }
`;

P.displayName = "P";

// eslint-disable-next-line
export default P;
