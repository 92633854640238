// @flow
import { combineReducers } from "redux";
import {
  reducer as homeReducer,
  type State as HomeState,
} from "../BlogHome/ducks";
import {
  reducer as categoryReducer,
  type State as CategoryState,
} from "../BlogCategory/ducks";
import {
  reducer as postReducer,
  type State as PostState,
} from "../BlogPost/ducks";

export type State = {
  home: HomeState,
  category: CategoryState,
  post: PostState,
};

export const reducer = combineReducers({
  home: homeReducer,
  category: categoryReducer,
  post: postReducer,
});
