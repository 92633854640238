import { ErrorPage } from "@nested/brand";
import { createUniversalRoute } from "../../utils/createUniversalRoute";
import { blogRoutes } from "../BlogLayout/routes";

// All area partners have their own website, which is built up of a url
// "nested.com/${area}". This includes for areas with multiple words, eg
// Queen's Park, which is "nested.com/queens-park". (nested.com/queen'spark
// will also go to this endpoint :tada:). Straying from this format will
// mean code changes in the backend.
export const routeList = [
  {
    path: "/rebrand-e2e-tests/:item?",
    name: "RebrandE2eTests",
  },
  {
    path: "/blog",
    name: "BlogLayout",
    routes: blogRoutes,
  },
  {
    path: "/for-sale/:postcodeSector/:roadName/:dealExternalId/direct-enquiry/:uuid",
    name: "PropertyEnquiryFromPropertyMatch",
  },
  {
    path: "/for-sale/:postcodeSector/:roadName/:dealExternalId/enquiry",
    name: "PropertyEnquiry",
  },
  {
    path: "/for-sale/:postcodeSector/:roadName/:dealExternalId",
    name: "IndividualPropertyPage",
  },
  {
    path: "/unsubscribe-email/:subscription/:emailId",
    name: "UnsubscribeEmail",
  },
  {
    path: "/unsubscribe-sms/:subscription/:smsId",
    name: "UnsubscribeSms",
  },
  {
    path: "/enquiry-thank-you/:dealExternalId",
    name: "EnquiryThankyou",
  },
  {
    // needed because bishops-stortford was set up with incorrect url
    path: "/stortford",
    name: "Redirect",
    props: { to: "/bishops-stortford" },
  },
  {
    path: "/:item?",
    name: "Page",
  },
];

export const routes = routeList.map((route) =>
  createUniversalRoute(route, ErrorPage),
);
