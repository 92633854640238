/**
 * @flow
 *
 * This logger is designed to follow the format specified by StackDriver for structured logging.
 * We use Fluentd to process our logs, so we only need to provide the jsonPayload. There are two
 * required keys for this to work: message (the log message) and severity (the log level). These
 * will be parsed and hoisted to the top level automatically by fluentd when it receives the message.
 * Any other keys provided will be included in the logs as JSON metadata
 * https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
 */

import pino from "pino";

const customLevels = {
  debug: 100,
  info: 200,
  warning: 400,
  error: 500,
  critical: 600,
};

const loggingEnabled = () => {
  if (process.env.NODE_ENV === "test") {
    return false;
  }

  if (process.env.NODE_ENV === "production" && process.browser) {
    return false;
  }

  return true;
};

export const logger: Logger = pino({
  customLevels,
  useOnlyCustomLevels: true,
  base: null,
  messageKey: "message",
  enabled: loggingEnabled(),
  redact: ["req.headers.cookie", 'res.headers["set-cookie"]'],
  formatters: {
    level(label) {
      // this changes the key from level to severity and uses the label instead of value
      return { severity: label };
    },
  },
});
