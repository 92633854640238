// @flow
/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

import { Sentry } from "@nested/isomorphic-sentry";
import { analyticsMiddleware } from "./utils/analytics/analyticsMiddleware";
import { PrismicClient } from "./utils/PrismicClient/PrismicClient";
import { createReducer } from "./reducers";

type ConfigureStore = {
  initialState: {},
  prismicClient: PrismicClient,
};

const errorMiddleware = () => (next) => (action) => {
  if (action.type.includes("_ERROR")) {
    /*
     * Trying to get a specific error message as label in Sentry so that
     * all issues are properly sorted. Defaulting to action type just in case
     */
    const error =
      action.payload?.error instanceof Error
        ? action.payload.error
        : new Error(action.type);

    Sentry.captureException(error, action);
  }
  return next(action);
};

export function configureStore({
  initialState = {},
  prismicClient,
}: ConfigureStore): ReduxStore {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      prismicClient,
    },
  });
  const middlewares = [sagaMiddleware, analyticsMiddleware, errorMiddleware];

  /* istanbul ignore else */
  if (process.browser) {
    // Add logger middleware if we are in a development environment
    /* istanbul ignore if */
    if (process.env.NODE_ENV === "development") {
      middlewares.push(reduxImmutableStateInvariant());
    }
  }

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  store.runSaga = sagaMiddleware.run;

  return store;
}
