import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const H4 = styled.h4`
  ${fontStyle({
    smallFontSize: "20px",
    smallLineHeight: "30px",
    mediumFontSize: "20px",
    mediumLineHeight: "30px",
  })};
`;

H4.displayName = "H4";

// eslint-disable-next-line
export default H4;
