// @flow
import { type Saga } from "redux-saga";
import { all, call, put, take, takeLatest } from "redux-saga/effects";
import { type Location } from "react-router";
import { matchRoutes } from "react-router-config";
import { routes } from "./routes";

export const LOCATION_CHANGE = "/app/LOCATION_CHANGE";
export const LOCATION_CHANGE_SUCCESS = "/app/LOCATION_CHANGE_SUCCESS";

type LocationChangeAction = {
  type: typeof LOCATION_CHANGE,
  payload: {
    location: Location,
  },
};

type LocationChangeSuccessAction = {
  type: typeof LOCATION_CHANGE_SUCCESS,
  payload: {},
};

export const locationChange = (location: Location) => ({
  type: LOCATION_CHANGE,
  payload: { location },
});

export const locationChangeSuccess = () => ({
  type: LOCATION_CHANGE_SUCCESS,
  payload: {},
});

type State = {
  transitioningTo?: Location,
  transitioningFrom?: Location,
  currentLocation?: Location,
};

type Action = LocationChangeAction | LocationChangeSuccessAction;

export const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        transitioningTo: action.payload.location,
        transitioningFrom: state.currentLocation,
      };
    case LOCATION_CHANGE_SUCCESS:
      return {
        currentLocation: state.transitioningTo,
      };
    default:
      return state;
  }
};

export function* locationPrefetchSaga({ route, match }: *): Saga<void> {
  const component = yield call(route.component.preload);
  if (component.fetchData) {
    yield call(component.fetchData, match);
  }
}

export function* locationChangeSaga({
  payload: { location },
}: LocationChangeAction): Saga<void> {
  try {
    const matches = yield call(matchRoutes, routes, location.pathname);
    yield all(matches.map((match) => call(locationPrefetchSaga, match)));
  } finally {
    yield put(locationChangeSuccess());
  }
}

export function* serverDataFetchSaga(): Saga<void> {
  const action = yield take(LOCATION_CHANGE);
  yield call(locationChangeSaga, action);
}

export function* browserDataFetchSaga(): Saga<void> {
  yield takeLatest(LOCATION_CHANGE, locationChangeSaga);
}
