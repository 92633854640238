import { random } from "lodash";

// eslint-disable-next-line
export default function getRandomVariation(experiment) {
  const randomInt = random(0, experiment.variations.length - 1);
  return {
    experimentId: experiment.googleId,
    variationId: experiment.variations[randomInt].id,
    variation: randomInt,
    ref: experiment.variations[randomInt].ref,
    experiment,
  };
}
