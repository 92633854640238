import { css } from "styled-components";

export const marginHelpers = css`
  ${({ marginTop, theme }) =>
    marginTop
      ? css`
          margin-top: calc(${theme.padding.base}* ${marginTop});
        `
      : ""} ${({ marginBottom, theme }) =>
    marginBottom
      ? css`
          margin-bottom: calc(${theme.padding.base}* ${marginBottom});
        `
      : ""} ${({ marginRight, theme }) =>
    marginRight
      ? css`
          margin-right: calc(${theme.padding.base}* ${marginRight});
        `
      : ""} ${({ marginLeft, theme }) =>
    marginLeft
      ? css`
          margin-left: calc(${theme.padding.base}* ${marginLeft});
        `
      : ""};
`;

export const paddingHelpers = css`
  ${({ paddingTop, theme }) =>
    paddingTop
      ? css`
          padding-top: calc(${theme.padding.base}* ${paddingTop});
        `
      : ""} ${({ paddingBottom, theme }) =>
    paddingBottom
      ? css`
          padding-bottom: calc(${theme.padding.base}* ${paddingBottom});
        `
      : ""} ${({ paddingRight, theme }) =>
    paddingRight
      ? css`
          padding-right: calc(${theme.padding.base}* ${paddingRight});
        `
      : ""} ${({ paddingLeft, theme }) =>
    paddingLeft
      ? css`
          padding-left: calc(${theme.padding.base}* ${paddingLeft});
        `
      : ""};
`;

// "wrap" is being deprecated in favor of "shouldWrap"
export const flexHelpers = css`
  display: flex;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "baseline"};
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  flex-wrap: ${({ wrap, shouldWrap }) =>
    wrap || shouldWrap ? "wrap" : "nowrap"};
`;
