import { get, hasIn } from "lodash";

function getGuaranteedStateObject({ state, path }) {
  return hasIn(state, path) ? get(state, path) : {};
}

export function createFetchedSelector({ component, uid }) {
  return function fetchedSelector(state) {
    return hasIn(state, ["cms", "fetched", component, uid]);
  };
}

// eslint-disable-next-line
export default function createComponentStateSelector({ component, uid }) {
  return function componentStateSelector(state) {
    return getGuaranteedStateObject({ state, path: ["cms", component, uid] });
  };
}
