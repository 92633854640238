import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const H2 = styled.h2`
  ${({ theme }) =>
    fontStyle({
      fontWeight: theme.font.bold,
      smallFontSize: "32px",
      smallLineHeight: "44px",
      mediumFontSize: "40px",
      mediumLineHeight: "54px",
    })};
`;

H2.displayName = "H2";

// eslint-disable-next-line
export default H2;
