// @flow
import type { Node } from "react";
import { theme as defaultTheme } from "@nested/ui";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { type ApolloClient, ApolloProvider } from "@apollo/client";
import { createApolloClient, ABTestProvider } from "@nested/utils";
import { ProgressProvider } from "./containers/ProgressContext/ProgressContext";

type Props = {
  children: Node,
  store: ReduxStore,
  client?: ApolloClient<any>,
  theme?: Object,
};

export const Providers = ({
  client = createApolloClient(),
  children,
  store,
  theme = defaultTheme,
}: Props) => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ProgressProvider>
        <ThemeProvider theme={theme}>
          <ABTestProvider>{children}</ABTestProvider>
        </ThemeProvider>
      </ProgressProvider>
    </Provider>
  </ApolloProvider>
);
