// @flow

import { all } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { cmsSaga } from "@nested/react-with-cms";

/*
 * All sagas here listen in the background for redux actions before doing
 * any work
 */
export function* backgroundSaga(): Saga<void> {
  yield all([cmsSaga()]);
}
