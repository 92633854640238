import { Children, cloneElement, createElement } from "react";
import styled from "styled-components";
import T from "prop-types";
import { A } from "@nested/nested-react-components";
import { camelCase } from "lodash";
import * as paragraphTypes from "./structuredTextParagraphTypes";
import renderSlices from "./renderSlices";

const B = styled.b`
  font-weight: ${({ theme }) => theme.font.bold};
`;

export const renderStructuredText = (paragraphsArray) => {
  if (!Array.isArray(paragraphsArray)) return null;

  const array = [];
  let unorderedList = [];
  let orderedList = [];
  paragraphsArray.forEach(({ type, children, ...rest }) => {
    switch (camelCase(type)) {
      case "button":
        array.push(paragraphTypes.button(rest));
        break;
      case "image":
        array.push(paragraphTypes.image(rest.src, rest.other));
        break;
      case "listItem":
        unorderedList.push(
          paragraphTypes[camelCase(type)](
            renderStructuredTextParagraph(children),
          ),
        );
        break;
      case "oListItem":
        orderedList.push(
          paragraphTypes[camelCase(type)](
            renderStructuredTextParagraph(children),
          ),
        );
        break;
      default:
        if (unorderedList.length > 0) {
          array.push(paragraphTypes.ul(unorderedList));
          unorderedList = [];
        }

        if (orderedList.length > 0) {
          array.push(paragraphTypes.ol(orderedList));
          orderedList = [];
        }
        array.push(
          paragraphTypes[camelCase(type)](
            renderStructuredTextParagraph(children),
          ),
        );
        break;
    }
  });
  if (unorderedList.length > 0) {
    array.push(paragraphTypes.ul(unorderedList));
  }

  if (orderedList.length > 0) {
    array.push(paragraphTypes.ol(orderedList));
  }

  // Adding keys to children
  return Children.map(array, (child, index) =>
    cloneElement(child, { key: index }),
  );
};

const toElement = ({ type, text, url }) => {
  switch (type) {
    case "strong":
      return <B>{text}</B>;
    case "em":
      return <i>{text}</i>;
    case "hyperlink":
      return <A href={url}>{text}</A>;
    default: {
      if (text.match(/\n/) === null) return text;
      const textArrayWithBreaks = text
        .split(/\n/)
        .map((textString) => (textString.length > 0 ? textString : <br />));
      return createElement("span", null, ...textArrayWithBreaks);
    }
  }
};

toElement.propTypes = {
  type: T.string,
  text: T.string,
  url: T.string,
};

export function renderStructuredTextParagraph(childrenArray) {
  return childrenArray.map(({ type, text, url }) =>
    type.reduce((acc, curr) => toElement({ type: curr, text: acc, url }), text),
  );
}

// eslint-disable-next-line
export { renderSlices };
