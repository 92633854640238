// @flow
import { createUniversalRoute } from "../../utils/createUniversalRoute";
import { routes as marketingRoutes } from "../../pages/Layout/routes";
import { techCareersRoutes } from "../../pages/TechCareersLayout/routes";

const createLegacyPrismicRoute = (path) => ({
  path,
  exact: true,
  name: "LegacyLayout",
  routes: [
    createUniversalRoute({
      path,
      exact: true,
      name: "MarketingLayout",
      routes: [
        createUniversalRoute({
          path: "/:item",
          name: "Custom",
        }),
      ],
    }),
  ],
});

const createLegacyStaticRoute = (path, name, config = {}) => ({
  path,
  exact: true,
  name: "LegacyLayout",
  routes: [
    createUniversalRoute({
      path,
      exact: true,
      name: "MarketingLayout",
      routes: [
        createUniversalRoute({
          path,
          name,
          ...config,
        }),
      ],
    }),
  ],
});

const createLegacyRoute = (path, name, config = {}) => ({
  path,
  name: "LegacyLayout",
  routes: [
    createUniversalRoute({
      path,
      name,
      ...config,
    }),
  ],
});

const allRoutes = [
  createLegacyPrismicRoute("/viewing-confirmed"),
  createLegacyPrismicRoute("/viewing-error"),
  createLegacyPrismicRoute("/partner-referrals"),
  createLegacyStaticRoute("/complaints-procedure", "Redirect", {
    props: { to: "/support/90523-policies/what-is-your-complaints-procedure" },
  }),
  createLegacyPrismicRoute("/your-home-valuation"),
  createLegacyStaticRoute("/performance", "Redirect", { props: { to: "/" } }),
  createLegacyRoute("/virtual-tours/:id", "VirtualTour"),
  createLegacyStaticRoute("/press/:item", "PressSingle"),
  createLegacyStaticRoute("/customer-stories", "CustomerStories"),
  createLegacyStaticRoute("/customer-stories/:item", "CustomerStory"),
  createLegacyStaticRoute("/guides/:item", "Redirect", { props: { to: "/" } }),
  createLegacyStaticRoute(
    "/research/:story/2017/:country/:category",
    "ResearchStory",
  ),
  createLegacyStaticRoute("/research", "Research"),
  createLegacyStaticRoute(
    "/how-long-will-it-take-to-sell-my-house",
    "Redirect",
    {
      props: { to: "/" },
    },
  ),
  createLegacyStaticRoute("/baby-move-calculator", "Redirect", {
    props: { to: "/" },
  }),
  createLegacyStaticRoute("/smoke-tests/:item", "SmokeTest"),
  createLegacyStaticRoute("/values", "Redirect", {
    props: { to: "/about" },
  }),
  createLegacyStaticRoute("/buying-advice", "Redirect", {
    props: { to: "/how-it-works" },
  }),
  createLegacyStaticRoute("/advance", "Redirect", {
    props: { to: "/how-it-works" },
  }),
  createLegacyStaticRoute("/agency", "Redirect", {
    props: { to: "/how-it-works" },
  }),
  createLegacyStaticRoute("/property-market/:area*", "Redirect", {
    props: { to: "/" },
  }),
  {
    path: "/get-started",
    name: "OnboardingForm",
  },
  { path: "/support/*", name: "SupportRedirect" },
  {
    path: "/technology*",
    name: "TechCareersLayout",
    routes: techCareersRoutes,
  },
  {
    path: "/",
    name: "Layout",
    routes: marketingRoutes,
  },
];

export const routes = allRoutes.map<*>(createUniversalRoute);
