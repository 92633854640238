// @flow
import styled, { css } from "styled-components";
import { media } from "../media";

export const headingStyles = css`
  color: ${({ theme }) => theme.palette.navy.medium};
  font-weight: ${({ light, theme }) =>
    light ? theme.typography.light : theme.typography.bold};
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: ${({ theme }) => theme.typography.maxWidth.large};
  text-align: ${({ left }) => (left ? "left" : "center")};

  ${media.tablet`
    text-align: left;
    ${({ center }) =>
      center &&
      css`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      `};
  `};
`;

export const H1Styles = css`
  ${headingStyles};

  font-size: 32px;
  line-height: 44px;

  ${media.tablet`
    max-width: 420px;
  `};

  ${media.desktop`
    max-width: ${({ theme }) => theme.typography.maxWidth.small};
    font-size: 56px;
    line-height: 71px;
  `};
`;

export const H1 = styled.h1`
  ${H1Styles};
`;

export const H2Styles = css`
  ${headingStyles};

  font-size: 28px;
  line-height: 40px;

  ${media.tablet`
    font-size: 40px;
    line-height: 56px;
  `};
`;

export const H2 = styled.h2`
  ${H2Styles};
`;

export const H3Styles = css`
  ${headingStyles};

  font-size: 24px;
  line-height: 32px;

  ${media.tablet`
    font-size: 32px;
    line-height: 44px;
  `};
`;

export const H3 = styled.h3`
  ${H3Styles};
`;

export const H4Styles = css`
  ${headingStyles};
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 30px;

  ${media.tablet`
    font-size: 24px;
    line-height: 36px;
    max-width: ${({ theme }) => theme.typography.maxWidth.small};
  `};
`;

export const H4 = styled.h4`
  ${H4Styles};
`;

export const H6Styles = css`
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.navy.medium};
  max-width: ${({ theme }) => theme.typography.maxWidth.large};
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: ${({ theme }) => theme.typography.bold};
  opacity: 0.5;
  margin-bottom: 20px;
  margin-top: 0px;
  text-align: ${({ left }) => (left ? "left" : "center")};
  ${media.tablet`
    text-align: left;
    ${({ center }) =>
      center &&
      css`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      `};
  `};
`;

export const H6 = styled.h6`
  ${H6Styles};
`;
