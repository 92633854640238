import styled, { css } from "styled-components";
import { media } from "@nested/ui";

export const PStyles = css`
  color: ${({ theme, shade = "light" }) => theme.palette.navy[shade]};
  font-size: 17px;
  line-height: 32px;
  font-weight: ${({ theme }) => theme.typography.light};
  margin-bottom: 10px;
  margin-top: 0px;
  max-width: ${({ theme }) => theme.typography.maxWidth.small};
  text-align: ${({ left }) => (left ? "left" : "center")};
  width: 100%;

  ${media.tablet`
    text-align: left;
    ${({ center }) =>
      center &&
      css`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      `};
  `};
`;
export const P = styled.p`
  ${PStyles};
`;

export const AStyles = css`
  color: ${({ theme }) => theme.palette.navy.medium};
  font-weight: ${({ theme }) => theme.typography.bold};
  text-decoration: underline;
  font-size: inherit;
  line-height: inherit;
`;

export const A = styled.a`
  ${AStyles};
`;

export const BStyles = css`
  font-weight: ${({ theme }) => theme.typography.bold};
`;
export const B = styled.strong`
  ${BStyles};
`;

export const Li = styled.li`
  ${PStyles}
`;
