/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from "redux";

import { reducer as cms } from "@nested/react-with-cms";
import { reducer as forSalePage } from "@nested/brand/src/slices/ForSaleList/ducks";
import { reducer as feesCalculator } from "./slices/FeeCalculator/ducks";
import { reducer as propertyListing } from "./pages/IndividualPropertyPage/ducks";
import { reducer as blog } from "./pages/BlogLayout/ducks";
import { reducer as navigation } from "./pages/MarketingLayout/Navigation/ducks";
import { reducer as customPages } from "./pages/Custom/ducks";
import { reducer as location } from "./containers/App/locationDucks";
import { AppReducer as mainReducer } from "./containers/App/reducer";

export function createReducer() {
  return combineReducers({
    main: mainReducer,
    cms,
    propertyListing,
    forSalePage,
    feesCalculator,
    blog,
    navigation,
    customPages,
    location,
  });
}
