// @flow
import styled from "styled-components";
import { getImage } from "@nested/utils";
import { H3 } from "../typography/heading";
import { P } from "../typography/paragraph";

const Wrapper = styled.div`
  text-align: center;
`;

const Img = styled.img`
  margin-bottom: 20px;
`;

type Props = {
  icon: string,
  message: string,
  title: string,
};

export const ErrorMessage = ({ icon, title, message }: Props) => (
  <Wrapper>
    <Img src={getImage(`icons/${icon}`)} />
    <H3 data-test="error-message-heading" as="div" center>
      {title}
    </H3>
    <P center>{message}</P>
  </Wrapper>
);
