import { css } from "styled-components";
import media from "./media";
import { marginHelpers, paddingHelpers } from "./positioning";

export const textStyle = css`
  font-family: ${({ theme }) => theme.font.family};
  color: ${({ theme }) => theme.colors.greys.dark};
  font-size: ${({ theme }) => theme.font.size.default};
  line-height: ${({ theme }) => theme.font.lineHeight.default};
`;

export const fontStyle = ({
  fontWeight,
  color,
  smallFontSize,
  smallLineHeight,
  mediumFontSize,
  mediumLineHeight,
}) => css`
  font-family: ${({ theme }) => theme.font.family};
  font-weight: ${({ theme }) => fontWeight || theme.font.light};
  color: ${({ theme }) => color || theme.colors.greys.dark};
  max-width: ${({ theme }) => theme.font.typographyBreakpoint};

  margin-top: 0;
  margin-bottom: 0;
  ${marginHelpers} ${paddingHelpers} font-size: ${smallFontSize};
  line-height: ${smallLineHeight};

  ${media.md`
    font-size: ${mediumFontSize};
    line-height: ${mediumLineHeight};
  `};
`;
