import { cloneDeep, set } from "lodash";
import cms from "..";

export function checkStringProvided(argument, argumentName = "Argument") {
  if (argument === undefined) {
    throw new Error(`${argumentName} was not provided.`);
  }
  if (typeof argument !== "string") {
    throw new Error(`${argumentName} must be a string.`);
  }
}

// eslint-disable-next-line
export default function reducer() {
  return (
    state = {
      fetched: {},
      version: null,
    },
    action,
  ) => {
    const newState = cloneDeep(state);

    const { type } = action;

    if (!type || !type.includes(cms.actionsPrefix)) {
      return newState;
    }

    switch (true) {
      case type === `${cms.actionsPrefix}/FETCH_CMS_INFO_SUCCESS`:
        return set(newState, "version", action.payload);
      case type.includes("_SUCCESS"): {
        const {
          payload: { template, uid, ...payload },
        } = action;
        checkStringProvided(template, "template (Component name)");
        checkStringProvided(uid, "uid (Unique component ID)");

        return set(newState, [template, uid], payload);
      }
      case type.includes("_ERROR"):
        return newState;
      default: {
        const {
          payload: { template, uid },
        } = action;
        return set(newState, ["fetched", template, uid], true);
      }
    }
  };
}
