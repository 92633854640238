import { sendAnalyticsEvent } from "@nested/analytics";

export function analyticsMiddleware() {
  return (next) => (action) => {
    if (!action.type.includes("GTM_EVENT")) {
      return next(action);
    }

    const event = { ...action.payload };

    sendAnalyticsEvent(event);
    return next(action);
  };
}
