// @flow
export const SET_NAVIGATION_COLOUR =
  "app/containers/Navigation/SET_NAVIGATION_COLOUR";

type SetNavigationAction = {
  type: typeof SET_NAVIGATION_COLOUR,
  payload: {
    colour: ?string,
  },
};

type State = {
  colour?: ?string,
};

export const setNavigationColour = (colour: ?string): SetNavigationAction => ({
  type: SET_NAVIGATION_COLOUR,
  payload: { colour },
});

export const reducer = (state: State = {}, action: SetNavigationAction) => {
  switch (action.type) {
    case SET_NAVIGATION_COLOUR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
