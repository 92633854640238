// these sizes are arbitrary and you can set them to whatever you wish
import { css } from "styled-components";

const sizes = ["tablet", "desktop", "maxDesktop"];

export const media = sizes.reduce((acc, label) => {
  /*
   * use em in breakpoints to work properly cross-browser and support users
   * changing their browsers font-size: https://zellwk.com/blog/media-query-units/
   */
  const rule = (...args) => css`
    @media (min-width: ${({ theme }) => theme.breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return {
    ...acc,
    [label]: rule,
  };
}, {});
