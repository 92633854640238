import { ErrorPage } from "@nested/brand";
import { createUniversalRoute } from "../../utils/createUniversalRoute";

export const routes = [
  {
    path: "/technology/:item?",
    name: "TechCareersPage",
  },
];

export const techCareersRoutes = routes.map((route) =>
  createUniversalRoute(route, ErrorPage),
);
