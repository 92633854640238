import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const A = styled.a`
  ${({ theme }) =>
    fontStyle({
      smallFontSize: "18px",
      smallLineHeight: "28px",
      mediumFontSize: "18px",
      mediumLineHeight: "28px",
      color: theme.colors.tertiary.default,
      fontWeight: theme.font.bold,
    })} text-decoration: underline;
  color: var(--link-color, ${({ theme }) => theme.colors.tertiary.default});
`;

A.displayName = "A";

// eslint-disable-next-line
export default A;
