// @flow
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React$Node,
};

export const ScrollToTop = ({ children }: Props) => {
  const location = useLocation();
  useEffect(() => {
    if (location.state?.noScroll) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};
