// @flow

export type PrimaryColour = "honey" | "yellow" | "peachy" | "blue" | "mint";

type SecondaryColour =
  | "grey"
  | "navy"
  | "white"
  | "black"
  | "hotpink"
  | "green";

type Typography = {
  fontFamily: string,
  light: number,
  bold: number,
  maxWidth: { small: string, large: string },
};

type ColourObject = {
  extraLight: string,
  light: string,
  medium: string,
  dark: string,
  ui: string,
  muted?: string,
};

type Palette = {
  [PrimaryColour | SecondaryColour]: ColourObject,
  data: {
    red: string,
    amber: string,
    green: string,
  },
  misc: {
    trustpilotGreen: string,
    grey95: string,
    grey93: string,
    smokeGrey: string,
    slateGrey: string,
    pinkPeach: string,
    fluoroMint: string,
  },
};

export type Theme = {
  typography: Typography,
  palette: Palette,
  breakpoints: Breakpoints,
};

type Breakpoints = {
  tablet: number,
  desktop: number,
  desktopMax: number,
};

export const theme: Theme = {
  typography: {
    fontFamily: "Euclid Circular B",
    light: 300,
    bold: 500,
    maxWidth: {
      small: "690px",
      large: "790px",
    },
  },
  palette: {
    honey: {
      extraLight: "hsl(37, 100%, 86%)",
      light: "hsl(37,100%,84%)", // #ffe0ad
      medium: "hsl(37,100%,77%)", // #ffd28a
      dark: "hsl(37,100%,70%)", // #ffc466
      ui: "hsl(37,100%,70%)", // #ffc466
    },
    yellow: {
      extraLight: "hsl(40,85%,67%)", // #F2C264
      light: "hsl(45.6,100%,50%)", // #FFC200
      medium: "hsl(45.6,100%,50%)", // #FFC200
      dark: "hsl(45.6,100%,50%)", // #FFC200
      ui: "hsl(45.6,100%,50%)", // #FFC200
      muted: "hsl(46.6, 96%, 45.9%)", // #E6B305
    },
    grey: {
      extraLight: "hsl(70, 12%, 90%)", // roughly #eceee7
      light: "hsl(70, 12%, 90%)", // roughly #eceee7
      medium: "hsl(70, 12%, 90%)", // roughly #eceee7
      dark: "hsl(70, 12%, 90%)", // roughly #eceee7
      ui: "hsl(70, 12%, 90%)", // roughly #eceee7
    },
    peachy: {
      extraLight: "hsl(20, 100%, 98%)", // roughly #fbf3ee
      light: "hsl(20,100%,92%)", // #ffe4d6
      medium: "hsl(20,100%,86%)", // #ffcfb8
      dark: "hsl(20,100%,78%)", // # #ffb48f
      ui: "hsl(13,53%,77%)", //  #e3b2a4
    },
    blue: {
      extraLight: "hsl(212,100%,98%)", // #f5faff
      light: "hsl(212,100%,96%)", // #ebf4ff
      medium: "hsl(212,100%,92%)", // #d6e9ff
      dark: "hsl(212,100%,86%)", // #b8d9ff
      ui: "hsl(212,100%,50%)", // #0077ff
      muted: "hsl(213,96%,45.9%)", // #056AE6
    },
    mint: {
      extraLight: "hsl(165, 48%, 94%)",
      light: "hsl(165,48%,92%)", // #e1f4ef
      medium: "hsl(165,48%,84%)", // #c1e9df
      dark: "hsl(165,48%,76%)", // #a4dfd0
      ui: "hsl(165, 100%, 60%)", // #33ffcc
    },
    navy: {
      extraLight: "hsl(219,21%,80%)",
      light: "hsl(219,21%,40%)", // #51607b
      medium: "hsl(219,21%,26%)", // #343e50 (also #353F52 due to a bad conversion by Sean)
      dark: "hsl(219,21%,18%)", // #242b38
      ui: "hsl(219,21%,18%)", // #242b38
    },
    white: {
      extraLight: "hsl(0, 0%, 100%)", // #ffffff
      light: "hsl(0, 0%, 100%)", // #ffffff
      medium: "hsl(0, 0%, 100%)", // #ffffff
      dark: "hsl(0, 0%, 100%)", // #ffffff
      ui: "hsl(0, 0%, 100%)", // #ffffff
    },
    black: {
      extraLight: "hsl(0, 0%, 50%)", // #000000
      light: "hsl(0, 0%, 50%)", // #000000
      medium: "hsl(0, 0%, 50%)", // #000000
      dark: "hsl(0, 0%, 50%)", // #000000
      ui: "hsl(0, 0%, 50%)", // #000000
    },
    hotpink: {
      extraLight: "hsl(343.16, 100%, 55%)", // #FF1B5B
      light: "hsl(343.16, 100%, 55%)", // #FF1B5B
      medium: "hsl(343.16, 100%, 55%)", // #FF1B5B
      dark: "hsl(343.16, 100%, 55%)", // #FF1B5B
      ui: "hsl(343.16, 100%, 55%)", // #FF1B5B
    },
    green: {
      extraLight: "hsl(160.6,100%,41.2%)", // #00D28E
      light: "hsl(160.6,100%,41.2%)", // #00D28E
      medium: "hsl(160.6,100%,41.2%)", // #00D28E
      dark: "hsl(160.6,100%,41.2%)", // #00D28E
      ui: "hsl(160.6,100%,41.2%)", // #00D28E
    },
    data: {
      red: "hsl(343, 100%, 55%)", // #ff1b5b
      amber: "hsl(46, 100%, 50%)", // #ffc200
      green: "hsl(149, 68%, 54%)", // #3ada88
    },
    misc: {
      trustpilotGreen: "hsl(160, 100%, 36%)", // #00b67a
      grey95: "hsl(0, 0%, 95%)", // #f3f3f3
      grey93: "hsl(0, 0%, 93%)", // #eeeeee
      smokeGrey: "#d8d8d8",
      slateGrey: " #95a0af",
      fluoroMint: "#E3FCF6",
      pinkPeach: "hsl(13, 82%, 83%)", // #F7BFB0
    },
  },
  breakpoints: {
    tablet: 720,
    desktop: 1024,
    desktopMax: 1024,
  },
};
