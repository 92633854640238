import { css } from "styled-components";

const smallParagraph = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  strong {
    font-weight: 500;
  }
`;

const mediumParagraph = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  strong {
    font-weight: 500;
  }
`;

const largeParagraph = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  strong {
    font-weight: 500;
  }
`;

const extraLargeParagraph = css`
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;

  strong {
    font-weight: 500;
  }
`;

export { smallParagraph, mediumParagraph, largeParagraph, extraLargeParagraph };
