import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const H3 = styled.h3`
  ${({ theme }) =>
    fontStyle({
      fontWeight: theme.font.bold,
      smallFontSize: "24px",
      smallLineHeight: "30px",
      mediumFontSize: "24px",
      mediumLineHeight: "30px",
    })};
`;

H3.displayName = "H3";

// eslint-disable-next-line
export default H3;
