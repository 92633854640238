import styled, { css } from "styled-components";

const Hr = styled.hr`
  width: 100%;
  max-width: ${({ theme }) => theme.font.typographyBreakpoint};
  border: 1px solid ${({ theme }) => theme.colors.greys.light};
  margin: ${({ theme }) => theme.padding.base} 0;

  ${({ inSection }) =>
    inSection
      ? css`
          margin-top: 0;
          margin-bottom: calc(${({ theme }) => theme.padding.base} * 10);
        `
      : ""};
`;

// eslint-disable-next-line
export default Hr;
