// @flow
import React from "react";
import { connect } from "react-redux";
import { type Location } from "react-router";

type OuterProps = {|
  children: React$Node,
|};

type Props = {|
  ...OuterProps,
  dispatch?: any, // eslint-disable-line
  transitioningTo?: Location,
|};

type State = {
  animating: boolean,
  progress: number,
  animationFrameRequestId?: AnimationFrameID,
};

// This is how long and at what percentage the loading bar moves until it hangs
export const MAX_LOADING_TIME = 3000;
export const MAX_LOADING_PROGRESS = 0.95;

const roundToTwoDecimalPlaces = (n) => Math.round(n * 100) / 100;

const ProgressContext = React.createContext<State>({
  animating: false,
  progress: 0,
});

export class ProgressAnimator extends React.Component<Props, State> {
  state = {
    animating: false,
    progress: 0,
    animationFrameRequestId: undefined,
  };

  componentDidUpdate(oldProps: Props) {
    const { transitioningTo } = this.props;
    if (!oldProps.transitioningTo && transitioningTo) {
      /* eslint-disable-next-line */
      this.setState({ progress: 0, animating: true });
      this.animate(performance.now());
    }

    if (oldProps.transitioningTo && !transitioningTo) {
      /* eslint-disable-next-line */
      this.setState({ progress: 1, animating: false });
      this.cancelAnimation();
    }
  }

  componentWillUnmount() {
    this.cancelAnimation();
  }

  animate(startTime: number) {
    const animationFrameRequestId = requestAnimationFrame((now) => {
      const distance = roundToTwoDecimalPlaces(
        (now - startTime) / MAX_LOADING_TIME,
      );
      this.setState({
        progress:
          distance > MAX_LOADING_PROGRESS ? MAX_LOADING_PROGRESS : distance,
      });
      this.animate(startTime);
    });
    this.setState({
      animationFrameRequestId,
    });
  }

  cancelAnimation() {
    const { animationFrameRequestId } = this.state;

    if (animationFrameRequestId) {
      window.cancelAnimationFrame(animationFrameRequestId);
    }
  }

  render() {
    const { progress, animating } = this.state;
    const { children } = this.props;
    return (
      <ProgressContext.Provider value={{ progress, animating }}>
        {children}
      </ProgressContext.Provider>
    );
  }
}

export const ProgressConsumer = ProgressContext.Consumer;

const mapStateToProps = (state) => ({
  transitioningTo: state.location.transitioningTo,
});

export const ProgressProvider = connect<Props, OuterProps, _, _, _, _>(
  mapStateToProps,
)(ProgressAnimator);
