// @flow
import { type Sentry as SentryType } from "./types";

let sentry;

if (window.Sentry && process.env.NODE_ENV !== "development") {
  sentry = window.Sentry;
} else {
  sentry = ({
    onLoad: () => undefined,
    init: () => undefined,
    configureScope: () => undefined,
    captureException: (error) =>
      console.log("Sentry captureException: ", error), // eslint-disable-line no-console
    captureMessage: (message) =>
      console.log("Sentry captureMessage: ", message), // eslint-disable-line no-console
    setUser: () => undefined,
  }: any);
}

// This is only here to shut eslint up
export const getSentryScriptTag = () => "";

export const Sentry = (sentry: SentryType);
