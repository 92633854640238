/* eslint-disable max-len */
import { createElement } from "react";
import T from "prop-types";
import styled from "styled-components";
import {
  P as OriginalP,
  H1,
  H2,
  H3,
  H4,
  H6,
  Hr,
  A,
  Button,
} from "@nested/nested-react-components";

const P = styled(OriginalP)`
  ${({ supporting }) =>
    supporting &&
    `
    font-size: 14px !important;
  `};
`;

export const paragraph = (children) => {
  if (children.indexOf("---") === 0) {
    return createElement(Hr, null);
  }
  return createElement(P, null, ...children);
};

export function image(src, other) {
  if (!other) return createElement("img", { src });

  if (other.linkTo === undefined) return <img src={src} alt={other.alt} />;

  return (
    <a href={other.linkTo.value.url}>
      <img src={src} alt={other.alt} />
    </a>
  );
}

export const listItem = (children) => (
  <li>{createElement(P, null, ...children)}</li>
);

export const oListItem = (children) => createElement("li", null, ...children);

export const ul = (children) => createElement("ul", null, ...children);
export const ol = (children) => createElement("ol", null, ...children);

export const heading1 = (children) => createElement(H1, null, ...children);
export const heading2 = (children) => createElement(H2, null, ...children);
export const heading3 = (children) => createElement(H3, null, ...children);
export const heading4 = (children) => createElement(H4, null, ...children);
export const heading6 = (children) => createElement(H6, null, ...children);

export const button = ({ label, link }) => (
  <Button as={A} href={link}>
    {label}
  </Button>
);

button.propTypes = {
  label: T.string.isRequired,
  link: T.string.isRequired,
};
