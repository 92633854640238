const sanitize = (uid) => uid.replace(/[^0-9a-zA-Z-]/g, "");

// eslint-disable-next-line
export default function ({
  // react-router 3 uses params and react-router 4 uses match
  // we want to support both these scenarios until we are sure
  // that nothing unexpected happens when the migration goes live
  // we can remove params after that and only use match
  props: { uid, params = {}, match: { params: router4Params = {} } = {} },
  options: { fetchBy } = {},
}) {
  if (fetchBy) {
    return `fetch-by-${fetchBy}`;
  }

  return sanitize(uid || params.item || router4Params.item || "default");
}
