/* eslint-disable max-classes-per-file */
// @flow
import Prismic from "prismic-javascript";
import { logger as defaultLogger } from "@nested/logger";
import { getConfig } from "@nested/config";

const { CMS_API } = getConfig();

const apiEndpoint = `${CMS_API}/v2`;

export class PrismicResourceNotFound extends Error {}

/**
 * In order for preview mode to work correctly on both the server and the client, we have to
 * pass the HTTP request object in to the prismic api object when doing SSR so it can read cookies. It works
 * without any configuration on the client side by reading from document.cookie internally.
 */
class PrismicClient {
  req: ?NestedRequest;

  client: ?any;

  ref: ?string;

  cache: ?PrismicCacheInterface;

  constructor(req?: NestedRequest, cache?: PrismicCacheInterface) {
    this.req = req;
    this.cache = cache;
  }

  logger = () => this.req?.log || defaultLogger;

  sanitizeUid = (uid: string): string => {
    const cleanUid = uid.replace(/[^0-9a-z-_]/gi, "");
    if (cleanUid !== uid) {
      this.logger().info(
        `PrismicClient: invalid characters in UID: ${uid}. Sanitized to: ${cleanUid}.`,
      );
    }
    return cleanUid;
  };

  api = async () => {
    if (!this.client) {
      this.logger().info("PrismicClient: fetching Prismic refs and metadata");
      this.client = await Prismic.api(apiEndpoint, {
        req: this.req,
        apiCache: this.cache,
        apiDataTTL: 300,
      });
      this.logger().info(
        "PrismicClient: competed fetching Prismic refs and metadata",
      );
    }
    return (this.client: any);
  };

  setStaticRef = (ref: string) => {
    this.ref = ref;
  };

  getPage = async (
    uid: string,
    type: ?string = "custom",
  ): Promise<PrismicCustomPage> => {
    const cleanUid = this.sanitizeUid(uid);
    const api = await this.api();
    this.logger().info(
      `PrismicClient: fetching Prismic custom page ${cleanUid}`,
    );
    const response = await api.getByUID(type, cleanUid, {
      ref: this.ref,
    });
    this.logger().info(
      `PrismicClient: completed fetching Prismic custom page ${cleanUid}`,
    );
    if (response) {
      return response;
    }
    throw new PrismicResourceNotFound("Page not found");
  };

  getPost = async (uid: string): Promise<PrismicPost> => {
    const cleanUid = this.sanitizeUid(uid);
    const api = await this.api();
    this.logger().info(`PrismicClient: fetching Prismic blog post ${cleanUid}`);
    const response = await api.getByUID("blog_post", cleanUid, {
      ref: this.ref,
      fetchLinks: [
        "blog_tag.label",
        "blog_author.name",
        "blog_author.photo",
        "blog_author.job",
        "blog_category.name",
        "blog_post.title",
        "blog_post.hero_image",
        "blog_post.summary",
      ],
    });
    this.logger().info(
      `PrismicClient: completed fetching Prismic blog post ${cleanUid}`,
    );
    if (response) {
      return response;
    }
    throw new PrismicResourceNotFound("Failed to fetch post");
  };

  getCategory = async (uid: string): Promise<PrismicCategory> => {
    const cleanUid = this.sanitizeUid(uid);
    const api = await this.api();
    this.logger().info(
      `PrismicClient: fetching Prismic blog category ${cleanUid}`,
    );
    const response = await api.getByUID("blog_category", cleanUid, {
      ref: this.ref,
      fetchLinks: [
        "blog_post.title",
        "blog_post.hero_image",
        "blog_post.category",
        "blog_post.summary",
        "blog_category.posts",
        "blog_category.name",
      ],
    });
    this.logger().info(
      `PrismicClient: completed fetching Prismic blog category ${cleanUid}`,
    );
    if (response) {
      return response;
    }
    throw new PrismicResourceNotFound("Failed to fetch category");
  };

  getBlogHome = async (): Promise<PrismicBlogHome> => {
    const api = await this.api();
    this.logger().info("PrismicClient: fetching Prismic blog home page");
    const response = await api.getSingle("blog_home", {
      ref: this.ref,
      fetchLinks: [
        "blog_post.title",
        "blog_post.description",
        "blog_post.hero_image",
        "blog_post.category",
        "blog_post.summary",
        "blog_category.name",
      ],
    });
    this.logger().info(
      "PrismicClient: completed fetching Prismic blog home page",
    );
    if (response) {
      return response;
    }
    throw new PrismicResourceNotFound("Failed to fetch home");
  };

  getPreviewRef = (cookies: { [key: string]: string }) => {
    const previewCookie = cookies[Prismic.previewCookie];
    if (!previewCookie) {
      return undefined;
    }
    return {
      ref: previewCookie,
      previewMode: true,
    };
  };

  // This is only required on the server in order to save a ref for react-with-cms
  getCurrentRef = async () => {
    const cookies = this.req?.cookies || {};
    const previewRef = this.getPreviewRef(cookies);
    if (previewRef) {
      return previewRef;
    }

    const api = await this.api();
    return api.masterRef;
  };
}

export { PrismicClient };
