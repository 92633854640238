//  @flow
import { type Saga } from "redux-saga";
import { select, call, put, getContext } from "redux-saga/effects";

export const GET_BLOG_HOME_SUCCESS = "app/pages/blog/GET_BLOG_HOME_SUCCESS";
export const GET_BLOG_HOME_ERROR = "app/pages/blog/GET_BLOG_HOME_ERROR";

const initialState = (): State => ({
  fetched: false,
});

export const getBlogHomeSuccess = (content: PrismicBlogHome) => ({
  type: GET_BLOG_HOME_SUCCESS,
  payload: {
    content,
  },
});

export const getBlogHomeError = (error: Error) => ({
  type: GET_BLOG_HOME_ERROR,
  payload: {
    error,
  },
});

type GetBlogHomeSuccessAction = {
  type: typeof GET_BLOG_HOME_SUCCESS,
  payload: {
    content: PrismicBlogHome,
  },
};

type GetBlogHomeErrorAction = {
  type: typeof GET_BLOG_HOME_ERROR,
  payload: {
    error: Error,
  },
};

type Action = GetBlogHomeSuccessAction | GetBlogHomeErrorAction;

export type State = RemoteContent<PrismicBlogHome>;

export const reducer = (
  state: State = initialState(),
  action: Action,
): State => {
  switch (action.type) {
    case GET_BLOG_HOME_SUCCESS:
      return { fetched: true, content: action.payload.content };
    case GET_BLOG_HOME_ERROR:
      return { fetched: false, error: action.payload.error };
    default:
      return state;
  }
};

const selectHome = ({ blog }) => blog.home;

export function* getBlogHomeSaga(): Saga<void> {
  const home = yield select(selectHome);
  if (home.fetched) {
    return;
  }
  try {
    const prismicClient = yield getContext("prismicClient");
    const result = yield call(prismicClient.getBlogHome);
    yield put(getBlogHomeSuccess(result));
  } catch (e) {
    yield put(getBlogHomeError(e));
  }
}
