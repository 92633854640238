export function createAnalyticsAction(data) {
  if (typeof data.event !== "string") {
    throw new Error('"event" property must be specified');
  }
  return {
    type: "nested/utils/analytics/GTM_EVENT",
    payload: {
      ...data,
    },
  };
}
