import { createElement } from "react";

// eslint-disable-next-line
export default function renderSlices(slicesArray, sliceTypes) {
  function renderSlice(sliceObject) {
    return sliceTypes[sliceObject.type](sliceObject);
  }

  return createElement("div", null, ...slicesArray.map(renderSlice));
}
