// @flow
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import md5 from "md5";
import { sendAnalyticsEvent } from "@nested/analytics";

const ONE_YEAR_IN_SECONDS = 31_536_000;

export const ABTestContext = createContext<{
  getTestGroup: (test: string, user: string) => "A" | "B",
  registerTestGroup: (test: string, group: "A" | "B") => void,
}>({});

const pushToDataLayer = (groups) => {
  sendAnalyticsEvent({
    splitTestGroups: Object.keys(groups).map((k) => `${k}_${groups[k]}`),
  });
};

export const ABTestProvider = ({ children }: { children?: React$Node }) => {
  const [cookies, setCookie] = useCookies();
  let initialGroups;

  try {
    initialGroups = JSON.parse(atob(cookies.splitTestGroups));
  } catch (e) {
    // Prevent somebody modifying our cookie or us changing to format from causing the page to crash
    initialGroups = {};
  }
  const [testGroups, updateTestGroups] = useState(initialGroups);

  useEffect(() => {
    pushToDataLayer(initialGroups);
  }, []);

  const registerTestGroup = (testId, splitGroup) => {
    const newGroups = {
      ...testGroups,
      [testId]: splitGroup,
    };

    pushToDataLayer(newGroups);

    /*
     * We encode the test JSON using base64 to:
     * a) Make people less likely to fuck with it and
     * b) Take up a bit less space by removing the need to URL encode special characters in the JSON
     */
    setCookie("splitTestGroups", btoa(JSON.stringify(newGroups)), {
      maxAge: ONE_YEAR_IN_SECONDS,
      path: "/",
    });

    updateTestGroups(newGroups);
  };

  const getTestGroup = (testId, userId) => {
    // We hash it with MD5 as this should have a reasonably random distribution of bytes even if the inputs are very similar
    const hash = md5(`${testId}${userId}`).substr(0, 9);
    const value = parseInt(hash, 16) % 100;
    return value < 50 ? "A" : "B";
  };

  return (
    <ABTestContext.Provider value={{ registerTestGroup, getTestGroup }}>
      {children}
    </ABTestContext.Provider>
  );
};
