import { css } from "styled-components";
import { useLocation } from "react-router";
import {
  mediumHeading,
  largeHeading,
  largeParagraph,
  media,
} from "@nested/brand";
import { Button } from "@nested/component-library";

const wrapperStyle = css`
  background-color: white;
  margin-top: 40px;
  padding: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  ${media.tablet`
    padding: 0px 20px;
    margin-top: 0px;
  `};
`;

const headingStyle = css`
  ${mediumHeading}
  margin: 39px auto 0;
  max-width: 878px;
  ${media.desktop`
    ${largeHeading}
  `}
`;

const paragraphStyle = css`
  ${largeParagraph}
  margin: 30px auto;
  max-width: 624px;
`;

const imageStyle = css`
  width: 160px;
`;

export const ErrorPage = ({ refreshPath }) => {
  const { pathname } = useLocation();
  return (
    <div css={wrapperStyle}>
      <div>
        <img
          className="chromatic-ignore"
          css={imageStyle}
          alt=""
          src="https://nested.imgix.net/icons/broken-page.gif"
        />
        <h1 css={headingStyle}>Something appears to have gone wrong.</h1>
        <p css={paragraphStyle}>
          One of our team has been alerted and they'll be working on a fix soon.
          Please try refreshing the page to see if that fixes the problem.
        </p>
        <Button
          onClick={() => {
            window.location = refreshPath || pathname;
          }}
          size="large"
          type="primary"
        >
          Reload page
        </Button>
      </div>
    </div>
  );
};
