// @flow
import universal from "react-universal-component";
import { UniversalComponentError } from "../components/UniversalComponentError";

const createUniversalComponent = (page, errorComponent) =>
  // $FlowFixMe this works but flow hates it
  universal(import(`../pages/${page}/index`), {
    error: errorComponent,
    ignoreBabelRename: true,
    key: "UniversalRender",
    loading: () => null,
  });

type UniversalRouteConfig = {
  name: string,
  path: string,
  exact?: boolean,
  props?: any,
};

const createUniversalRoute = (
  { name, ...route }: UniversalRouteConfig,
  errorComponent: any = UniversalComponentError,
) => ({
  ...route,
  component: createUniversalComponent(name, errorComponent),
});

export { createUniversalRoute };
