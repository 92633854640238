import { css } from "styled-components";
import * as transitions from "../../styles/transitions";
import { textStyle } from "../../styles/text";
import media from "../../styles/media";
import boxShadow, { buttonShadow } from "../../styles/boxShadow";

const primaryButtonStyles = css`
  background-color: var(
    --primary-colour,
    ${({ theme }) => theme.colors.primary.default}
  );
  color: var(--primary-text-colour, ${({ theme }) => theme.colors.greys.dark});

  &:hover {
    color: var(--primary-text-hover, ${({ theme }) => theme.colors.greys.dark});
    background-color: var(
      --primary-colour-hover,
      ${({ theme }) => theme.colors.primary.default}
    );
  }

  &:active {
    color: var(
      --primary-text-active,
      ${({ theme }) => theme.colors.greys.dark}
    );
    background-color: var(
      --primary-colour-active,
      ${({ theme }) => theme.colors.primary.default}
    );
  }
`;

const disabledButtonStyles = css`
  background-color: ${({ theme }) => theme.colors.greys.light};

  &:hover {
    cursor: not-allowed;
  }
`;

const secondaryButtonStyles = css`
  background-color: var(
    --secondary-colour,
    ${({ theme }) => theme.colors.greys.white}
  );
  color: var(
    --secondary-text-colour,
    ${({ theme }) => theme.colors.tertiary.default}
  );

  &:hover {
    color: var(
      --secondary-text-hover,
      ${({ theme }) => theme.colors.tertiary.light}
    );
    background-color: var(
      --secondary-colour-hover,
      ${({ theme }) => theme.colors.greys.white}
    );
  }

  &:active {
    color: var(
      --secondary-text-active,
      ${({ theme }) => theme.colors.tertiary.dark}
    );
    background-color: var(
      --secondary-colour-active,
      ${({ theme }) => theme.colors.greys.white}
    );
  }
`;

const buttonStyles = css`
  border: none;
  display: block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  ${textStyle}
  max-width: calc(100% - ${({ theme }) => theme.padding.base} * 2);
  ${media.sm`
    max-width: 100%;
  `}
  min-width: 140px;
  width: 100%;
  height: ${({ theme }) => theme.form.element.height.default};
  border-radius: ${({ theme }) => theme.border.radius};
  font-weight: ${({ theme }) => theme.font.bold};
  ${buttonShadow}

  ${transitions.ease}

  &:hover {
    ${transitions.ease}
    transform: translateY(-2px);
    ${boxShadow}
  }

  &:active {
    ${transitions.ease}
    transform: translateY(1px);
    ${buttonShadow}
  }

  padding: ${({ theme }) => theme.padding.base};

  ${({ secondary, disabled }) => {
    if (disabled) return disabledButtonStyles;
    if (secondary) return secondaryButtonStyles;
    return primaryButtonStyles;
  }}

  ${media.sm`
    width: auto;
  `}
`;

// eslint-disable-next-line
export default buttonStyles;
