import { snakeCase } from "lodash";

function format(string) {
  return snakeCase(string).toUpperCase();
}

function typeCreator(actionPrefix, component, uid, suffix) {
  return `${actionPrefix}/${format(component)}/${format(uid)}${suffix || ""}`;
}

function typesCreator(actionPrefix, component, uid) {
  return {
    type: typeCreator(actionPrefix, component, uid),
    successType: typeCreator(actionPrefix, component, uid, "_SUCCESS"),
    errorType: typeCreator(actionPrefix, component, uid, "_ERROR"),
  };
}

// eslint-disable-next-line
export default typesCreator;
