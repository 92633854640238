// @flow
import { type Saga } from "redux-saga";
import { select, call, put, getContext } from "redux-saga/effects";
import { type Match } from "react-router";

export const GET_CATEGORY_SUCCESS = "app/pages/blog/GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "app/pages/blog/GET_CATEGORY_ERROR";

export type State = {
  [key: string]: RemoteContent<PrismicCategory>,
};

type GetCategorySuccessAction = {
  type: typeof GET_CATEGORY_SUCCESS,
  payload: {
    id: string,
    content: PrismicCategory,
  },
};

type GetCategoryErrorAction = {
  type: typeof GET_CATEGORY_ERROR,
  payload: {
    id: string,
    error: Error,
  },
};

type Action = GetCategorySuccessAction | GetCategoryErrorAction;

export const getCategorySuccess = (id: string, content: PrismicCategory) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: {
    id,
    content,
  },
});

export const getCategoryError = (id: string, error: Error) => ({
  type: GET_CATEGORY_ERROR,
  payload: {
    id,
    error,
  },
});

export const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          fetched: true,
          content: action.payload.content,
        },
      };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          fetched: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const selectCategory = ({ blog }, id) => blog.category[id];

export function* getBlogCategorySaga({
  params: { id },
}: Match<{ id: string }>): Saga<void> {
  const category = yield select(selectCategory, id);
  if (category?.fetched) {
    return;
  }

  try {
    const prismicClient = yield getContext("prismicClient");
    const result = yield call(prismicClient.getCategory, id);
    yield put(getCategorySuccess(id, result));
  } catch (e) {
    yield put(getCategoryError(id, e));
  }
}
