// @flow

class RequestError extends Error {
  response: Response;

  badResponseCode: boolean;
}

async function parseJSON(response) {
  try {
    const json = await response.json();
    return json;
  } catch (error) {
    error.response = response;
    throw error;
  }
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new RequestError(
    `Response with bad code received: ${response.status}.
     Message: ${response.statusText}.
     Request URL: ${response.url}`,
  );
  error.response = response;
  error.badResponseCode = true;
  throw error;
}

async function retryIfNetworkFailure({
  response,
  url,
  options,
  maxAttempts,
  attempts,
}) {
  if (response.badResponseCode) {
    throw response;
  }
  if (attempts < maxAttempts) {
    return makeRequest({ url, options, maxAttempts, attempts });
  }
  throw response;
}

type QueryParams = {
  [string]: string | number,
};

export function addQueryParams(baseUrl: string, queryParams: QueryParams = {}) {
  if (Object.keys(queryParams).length === 0) return baseUrl;

  const connector =
    typeof baseUrl === "string" && baseUrl.includes("?") ? "&" : "?";

  let urlWithParams = `${baseUrl}${connector}`;

  Object.keys(queryParams).forEach((key, index, allKeys) => {
    urlWithParams += `${key}=${queryParams[key]}`;
    if (index < allKeys.length - 1) {
      urlWithParams += "&";
    }
  });

  return urlWithParams;
}

async function makeRequest({ url, options, maxAttempts, attempts }) {
  let response;
  try {
    response = await fetch(url, options);
    checkStatus(response);
  } catch (error) {
    return retryIfNetworkFailure({
      response: error,
      url,
      options,
      maxAttempts,
      attempts: attempts + 1,
    });
  }
  return parseJSON(response);
}

function defaultMaxAttempts({ method }) {
  if (typeof method === "undefined") {
    return 2;
  }
  return method === "GET" ? 2 : 1;
}

type Config = RequestOptions & {
  maxAttempts?: number,
};

export async function request(
  url: string,
  { maxAttempts, ...options }: Config = {},
) {
  const defaultedMaxAttempts = maxAttempts || defaultMaxAttempts(options);
  return makeRequest({
    url,
    options,
    maxAttempts: defaultedMaxAttempts,
    attempts: 0,
  });
}
