// @flow
import { Link } from "react-router-dom";

const formatNestedLink = (to: string): string => {
  if (isBrowser()) {
    const currentDomain = window.location.host;

    const parsedUrl = extractUrl(to);

    if (parsedUrl !== null && parsedUrl.domain === currentDomain) {
      return parsedUrl.path;
    }
  }
  return to;
};

const isAbsolute = (to: string): boolean => {
  return to.includes("http") || to.includes("tel:") || to.includes("mailto:");
};

type Props = {|
  to?: string,
  children: React$Node,
|};

export const SmartLink = ({ to = "/", children, ...rest }: Props) => {
  const link = formatNestedLink(to);

  if (isAbsolute(link)) {
    return (
      <a href={link} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link to={link} {...rest}>
      {children}
    </Link>
  );
};

const isBrowser = (): boolean => {
  return typeof window !== "undefined" && window.document;
};

type ParsedUrl = {
  domain: string,
  path: string,
};

const extractUrl = (url: string): ParsedUrl | null => {
  const matches = url.match(/^https?:\/\/([^/?#]+)(.*)/i);
  return matches && { domain: matches[1], path: matches[2] };
};
