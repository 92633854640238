import { css } from "styled-components";

const boxShadow = css`
  box-shadow: 0 4px 8px 0 rgba(34, 34, 34, 0.1);
`;

export const buttonShadow = css`
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
`;

// eslint-disable-next-line
export default boxShadow;
