import { fetchCmsInfoSuccess } from "./withCms/actions";
import { getRef } from "./fetch";

export async function configure({ options, store, storedVersion = {} }) {
  try {
    const cms = Object.assign(this, options);

    if (store) {
      const cmsInfo = await getRef(cms, {
        experimentId: storedVersion.experimentId,
        variationId: storedVersion.variationId,
      });

      store.dispatch(fetchCmsInfoSuccess(cms, cmsInfo));
      return cmsInfo;
    }

    return null;
  } catch (error) {
    return { error };
  }
}
