// @flow
export const generatePossibleTypes = (schema: any) => {
  // eslint-disable-next-line
  return schema.__schema.types.reduce((acc, supertype) => {
    if (supertype.possibleTypes) {
      return {
        ...acc,
        [supertype.name]: supertype.possibleTypes.map(({ name }) => name),
      };
    }

    return acc;
  }, {});
};
