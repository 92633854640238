import { call } from "redux-saga/effects";

import cmsUrl from "../helpers/cmsUrl";
import { formatPrismicObject } from "./transform";
import cms from "..";

export function* fetchByUid({ template: type, uid = type, ref }) {
  const queryParams = {
    page: "1",
    pageSize: "1",
    ref,
    q: `[[:d = at(my.${type}.uid, "${uid}")]]`,
  };

  const json = yield call(
    cms.networkLayer,
    cmsUrl({ cms, endpoint: "/documents/search", params: queryParams }),
  );

  if (json.results_size === 0) return yield null;

  const data = json.results[0].data[type];

  return yield {
    uid,
    ...formatPrismicObject(data),
  };
}
