// @flow

import type { Saga } from "redux-saga";
import { type Match } from "react-router";

import { call, put, select } from "redux-saga/effects";
import { request } from "@nested/utils";
import { getConfig } from "@nested/config";
import { fetchForSaleProperties } from "@nested/brand/src/slices/ForSaleList/ducks";

export const PROPERTY_LISTING_SUCCESS =
  "app/pages/IndividualPropertyPage/PROPERTY_LISTING_SUCCESS";
export const PROPERTY_LISTING_ERROR =
  "app/pages/IndividualPropertyPage/PROPERTY_LISTING_ERROR";

const { GOOGLE_CLOUD_STORAGE_API_URL } = getConfig();

const initialState = (): PropertyListing => ({
  listingConfig: (undefined: any),
  dealExternalId: null,
  fetched: false,
});

export const getDealExternalId = (state: ReduxStore) =>
  state.propertyListing.dealExternalId;
export const getFetched = (state: ReduxStore) => state.propertyListing.fetched;

export function reducer(
  state: PropertyListing = initialState(),
  action: Action,
) {
  switch (action.type) {
    case PROPERTY_LISTING_SUCCESS:
      return {
        ...state,
        fetched: true,
        listingConfig: action.payload.json,
      };
    case PROPERTY_LISTING_ERROR:
      return {
        ...state,
        fetched: true,
      };
    default:
      return state;
  }
}

/* eslint-disable flowtype/generic-spacing */
type PropertyListingSuccessAction = $Call<
  typeof propertyListingSuccessAction,
  JSON,
>;
export const propertyListingSuccessAction = (json: JSON) => ({
  type: PROPERTY_LISTING_SUCCESS,
  payload: { json },
});

type PropertyListingErrorAction = $Call<
  typeof propertyListingErrorAction,
  Error,
>;
export const propertyListingErrorAction = (error: Error) => ({
  type: PROPERTY_LISTING_ERROR,
  payload: { error },
});
/* eslint-enable flowtype/generic-spacing */

type Action = PropertyListingSuccessAction | PropertyListingErrorAction;

export function* fetchPropertyListings({
  params: { dealExternalId },
}: Match<{ dealExternalId: string }>): Saga<void> {
  const fetched = yield select(getFetched);

  if (!fetched) {
    if (!GOOGLE_CLOUD_STORAGE_API_URL) {
      throw new Error("Google Cloud Storage API URL does not exist.");
    }
    try {
      const fileName = `${dealExternalId}/config.json`;
      const configUrl = `${GOOGLE_CLOUD_STORAGE_API_URL}/b/nested-property-listings/o/${encodeURIComponent(
        fileName,
      )}?alt=media`;
      const jsonResponse = yield call(request, configUrl);
      yield put(propertyListingSuccessAction(jsonResponse));
      yield call(fetchForSaleProperties);
    } catch (err) {
      yield put(propertyListingErrorAction(err));
    }
  }
}
