import { call } from "redux-saga/effects";

import cmsUrl from "../helpers/cmsUrl";
import { formatPrismicObject } from "./transform";
import cms from "..";

export function* fetchByType({ template: type, ref }) {
  const queryParams = {
    page: "1",
    pageSize: "10",
    ref,
    q: `[[:d = at(document.type, "${type}")]]`,
  };

  const json = yield call(
    cms.networkLayer,
    cmsUrl({ cms, endpoint: "/documents/search", params: queryParams }),
  );
  if (json.results_size === 0) return yield null;

  const results = json.results.map(({ uid, data }) => ({
    uid,
    ...formatPrismicObject(data[type]),
  }));

  return yield results;
}
