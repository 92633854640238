// @flow
import styled, { css } from "styled-components";
import { SmartLink } from "../SmartLink/SmartLink";

const primaryStyle = css`
  background-color: ${({ theme }) => theme.palette.hague};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.palette.hague90};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.hague150};
  }
`;

const standardStyle = css`
  background-color: ${({ theme }) => theme.palette.sand100};
  color: ${({ theme }) => theme.palette.hague};
  &:hover {
    background-color: ${({ theme }) => theme.palette.sand80};
  }
  &:active {
    background-color: #e8dccf;
  }
`;

const accentStyle = css`
  background-color: ${({ theme }) => theme.palette.terracotta100};
  color: ${({ theme }) => theme.palette.hague};
  &:hover {
    background-color: ${({ theme }) => theme.palette.terracotta90};
  }
  &:active {
    background-color: #ff6d56;
  }
`;

const pinkStyle = css`
  background-color: ${({ theme }) => theme.palette.pink100};
  color: ${({ theme }) => theme.palette.hague};
`;

const linkStyle = css`
  background-color: transparent;
  color: #2d89d9;
  &:hover {
    color: ${({ theme }) => theme.palette.blue100};
  }
  &:active {
    color: #1e77d2;
  }
`;

const whiteStyle = css`
  background-color: white;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: white;
  }
`;

const secondaryStyle = css`
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  ${whiteStyle}
`;

const getButtonStyle = ({ $buttonStyle: type }) => {
  switch (type) {
    case "standard":
      return standardStyle;
    case "primary":
    case "submit":
      return primaryStyle;
    case "secondary":
      return secondaryStyle;
    case "accent":
      return accentStyle;
    case "link":
      return linkStyle;
    case "white":
      return whiteStyle;
    case "pink":
      return pinkStyle;
    default:
      throw new Error(
        `Invalid button type ${type}. It must be one of standard, primary, accent, link or white`,
      );
  }
};

const disabledStyle = css`
  &:disabled {
    background-color: ${({ theme }) => theme.palette.hague10};
    color: ${({ theme }) => theme.palette.hague50};
    cursor: default;
    &:hover {
      background-color: ${({ theme }) => theme.palette.hague10};
    }
    &:active {
      background-color: ${({ theme }) => theme.palette.hague10};
    }
  }
`;

const disabledLinkStyle = css`
  background-color: ${({ theme }) => theme.palette.hague10};
  color: ${({ theme }) => theme.palette.hague50};
  cursor: default;
  &:hover {
    background-color: ${({ theme }) => theme.palette.hague10};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.hague10};
  }
`;

const ButtonWrapper = styled.button`
  border-radius: 4px;
  border: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  padding: 0px 15px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  &:focus {
    text-decoration: underline;
  }
  ${({ size }) =>
    size === "large" &&
    css`
      height: 55px;
      line-height: 55px;
      padding: 0px 30px;
      font-size: 18px;
    `}
  ${getButtonStyle};
`;

type Props = {|
  noDisabledStyle?: boolean,
  noText?: boolean,
  htmlType?: string,
  children: React$Node,
  type?:
    | "accent"
    | "link"
    | "primary"
    | "secondary"
    | "standard"
    | "submit"
    | "white"
    | "pink",
  size?: "medium" | "large",
|};

type LinkProps = {|
  ...Props,
  to: string,
  disabled?: boolean,
|};

/*
 * `noDisabledStyle` means the disabled state of the button looks the same as the normal state. This is useful for things
 * like CTAs where you want it to stand out even when it is disabled.
 */
export const Button = ({
  children,
  noDisabledStyle,
  type = "standard",
  size = "medium",
  htmlType,
  ...rest
}: Props) => (
  <ButtonWrapper
    size={size}
    css={noDisabledStyle || disabledStyle}
    $buttonStyle={type}
    type={htmlType}
    {...rest}
  >
    {children}
  </ButtonWrapper>
);

export const ButtonLink = ({
  children,
  noDisabledStyle,
  noText,
  type = "standard",
  size = "medium",
  disabled = false,
  ...rest
}: LinkProps) => (
  <ButtonWrapper
    // We can't disable an <a> tag, so if it is disabled, we should keep it as a button
    as={disabled ? undefined : SmartLink}
    size={size}
    css={disabled ? disabledLinkStyle : null}
    $buttonStyle={type}
    disabled={disabled}
    {...rest}
  >
    {children}
  </ButtonWrapper>
);
