// @flow
import {
  HttpLink,
  ApolloClient,
  ApolloLink,
  split,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { getConfig } from "@nested/config";
import { generatePossibleTypes } from "../../generatePossibleTypes";
// eslint-disable-next-line
import schema from "../../../../schema.public.json";

const { GRAPHQL_URI, ACCOUNT_DEMO_MOCKS_URL } = getConfig();

const possibleTypes = generatePossibleTypes(schema);

// Apollo and Absinthe have a different protocol resulting in a different and invalid format for ApolloCache
export const absintheAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => ({
    ...response,
    ...response.payload,
  })),
);

export const authLink = setContext(async (_, { headers, auth }) => {
  try {
    const token = auth?.getToken && (await auth.getToken());
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } catch (e) {
    return {};
  }
});

export const locationLink = setContext(async (_, { headers }) => {
  try {
    if (typeof window !== "undefined") {
      return {
        headers: {
          ...headers,
          "x-current-url": `${window.location.origin}${window.location.pathname}`,
        },
      };
    }
    return headers;
  } catch (e) {
    return headers;
  }
});

const httpLink = new BatchHttpLink({
  uri: GRAPHQL_URI,
});

const demoLink = new HttpLink({ uri: ACCOUNT_DEMO_MOCKS_URL });

const link = ApolloLink.from([
  absintheAfterware,
  authLink,
  locationLink,
  split(
    ({ getContext }) => {
      const { auth } = getContext();
      return auth?.demoModeEnabled;
    },
    demoLink,
    httpLink,
  ),
]);

const getInitialState = () => {
  if (process.browser) {
    return window.APOLLO_STATE;
  }
  return {};
};

export const createCache = () =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      PublicDeal: {
        fields: {
          property: {
            merge: true,
          },
        },
      },
      PublicDealProperty: {
        keyFields: [],
      },
      Sm: {
        keyFields: [],
      },
      PostcodeDetails: {
        keyFields: ["normalisedPostcode"],
      },
    },
  });

export const createApolloClient = (options: Object = {}) =>
  new ApolloClient({
    link,
    cache: createCache().restore(getInitialState()),
    ...options,
  });
