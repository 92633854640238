import { upperFirst } from "lodash";
import * as fetchGenerators from "../fetch";

export function fetchCmsInfoSuccess(cms, cmsInfo) {
  return {
    type: `${cms.actionsPrefix}/FETCH_CMS_INFO_SUCCESS`,
    payload: cmsInfo,
  };
}

export function createSuccessCallback(type, template, uid) {
  return function successCallback(payload) {
    return {
      type,
      payload: {
        template,
        uid,
        ...payload,
      },
    };
  };
}

export function createErrorCallback(type) {
  return function errorCallback(error) {
    return {
      type,
      payload: { error },
    };
  };
}

const fetchFromCmsActionCreator = ({
  type,
  successType,
  errorType,
  uid,
  template,
  emptyCallback,
  fetchBy = "uid",
}) => {
  if (emptyCallback && typeof emptyCallback !== "function") {
    throw new Error('"emptyCallback" was provided, but is not a function.');
  }

  return {
    type,
    fetchData: true,
    payload: {
      template,
      uid,
      successCallback: createSuccessCallback(successType, template, uid),
      errorCallback: createErrorCallback(errorType),
      emptyCallback,
      fetchFunction: fetchGenerators[`fetchBy${upperFirst(fetchBy)}`],
    },
  };
};

// eslint-disable-next-line
export default fetchFromCmsActionCreator;
