import T from "prop-types";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  setPropTypes,
  flattenProp,
  setDisplayName,
  mapProps,
} from "recompose";
import stringToComponentName from "../helpers/stringToComponentName";
import typesCreator from "./types";
import createCmsReducer from "./reducer";
import defaultActionCreator from "./actions";
import createSelector, { createFetchedSelector } from "./selectors";
import getUid from "./utils/getUid";

const actionPrefix = "nested/withCms";

export function componentWillMount() {
  fetchIfDataEmpty(this.props);
}

export function componentWillUpdate(nextProps) {
  fetchIfDataEmpty(nextProps);
}

export function fetchIfDataEmpty(props) {
  const { fetched, fetchData, uid } = props;

  if (fetched) {
    return;
  }

  fetchData(uid);
}

export const fetchFromCms = lifecycle({
  componentWillMount,
  componentWillUpdate,
});

export const setDefaultPropTypes = setPropTypes({
  uid: T.string,
  params: T.shape({
    item: T.string,
  }),
});

export function createMapStateToProps(
  createComponentStateSelector = createSelector,
) {
  return function mapStateToProps(state, props) {
    const componentStateSelector = createComponentStateSelector(props);
    const fetchedSelector = createFetchedSelector(props);

    return {
      data: componentStateSelector(state),
      fetched: fetchedSelector(state),
    };
  };
}

export const defaultMapDispatchToProps =
  (customActionCreator) => (dispatch, props) => ({
    fetchData: (uid) => {
      const action =
        (customActionCreator && customActionCreator(uid)) ||
        defaultActionCreator(props);
      dispatch(action);
    },
  });

export const withCms = ({
  dataSelector,
  fetchAction,
  template,
  displayName,
  emptyCallback,
  fetchBy,
}) => {
  if (displayName) {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: "displayName" property is deprecated. Use "template" instead.',
    );
  }

  return compose(
    setDisplayName(displayName || stringToComponentName(template)),
    mapProps((props) => {
      const component = template || displayName;
      const uid = getUid({ props, options: { template, fetchBy } });
      return {
        ...props,
        uid,
        component,
        emptyCallback,
        template,
        fetchBy,
        ...typesCreator(actionPrefix, template, uid),
      };
    }),
    connect(
      createMapStateToProps(dataSelector),
      defaultMapDispatchToProps(fetchAction),
    ),
    setDefaultPropTypes,
    fetchFromCms,
    flattenProp("data"),
  );
};

export const reducer = createCmsReducer();
