// @flow

/*
 * Exclude anaytics from 3rd party automated tests (i.e. Pingdom and Calibre)
 * and anyone who has opted-out of tracking.
 * Should be IE11+ compliant as runs inside HEAD before main app.
 */
const includeLogic =
  '(navigator.userAgent.toLowerCase().indexOf("pingdom") === -1) && navigator.doNotTrack !== "1" && (document.cookie.indexOf("nested-test-traffic-do-not-track") === -1)';

export const getGoogleScripts = (isProduction: boolean) =>
  isProduction
    ? `
      <!-- Google Tag Manager (Live environment)-->
      <script>
      if(${includeLogic}){
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TRKMQS');
      }
      </script>
      <!-- End Google Tag Manager -->
      `
    : `<!-- Google tag manager skipped -->`;
