export const span = (text) => ({ type: ["text"], text });

export const strong = (text, prismicSpan) => ({
  type: [prismicSpan.type],
  text,
});

// Repetition!
export const em = (text, prismicSpan) => ({ type: [prismicSpan.type], text });

export const hyperlink = (text, prismicSpan) => ({
  type: [prismicSpan.type],
  url: prismicSpan.data.value.url,
  text,
});
