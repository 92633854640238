// @flow
import React from "react";
import { type ContextRouter, type Location } from "react-router";
import { withRouter, Route } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { compose } from "redux";
import { connect } from "react-redux";
import { ScrollToTop } from "@nested/ui";
import { Sentry } from "@nested/isomorphic-sentry";
import { ServerError } from "../../pages/ServerError";
import { MetaTags } from "./MetaTags";
import { routes } from "./routes";
import { locationChange } from "./locationDucks";

type Props = {
  handleLocationChange(location: Location): void,
  transitioningFrom?: Location,
  currentLocation?: Location,
} & ContextRouter<{}>;

type State = {
  hasError: boolean,
};

export class AppComponent extends React.Component<Props, State> {
  state = { hasError: false };

  componentDidUpdate(prevProps: Props) {
    const { location, handleLocationChange } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      handleLocationChange(location);
    }
  }

  componentDidCatch(e: Error, context: any) {
    Sentry.captureException(e, {
      extra: {
        context: JSON.stringify(context),
      },
    });
    this.setState({ hasError: true });
  }

  render() {
    const { transitioningFrom, currentLocation, location } = this.props;
    const current = transitioningFrom || currentLocation || location;
    const { hasError } = this.state;

    if (hasError) {
      return <ServerError />;
    }

    return (
      <ScrollToTop pathname={current.pathname}>
        <MetaTags />
        <Route location={current} render={() => renderRoutes(routes)} />
      </ScrollToTop>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLocation: state.location.currentLocation,
  transitioningFrom: state.location.transitioningFrom,
});

const mapDispatchToProps = (dispatch: *) => ({
  handleLocationChange: (location) => dispatch(locationChange(location)),
});

export const App = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AppComponent);
