// @flow
import { createUniversalRoute } from "../../utils/createUniversalRoute";

const routes = [
  {
    path: "/blog",
    exact: true,
    name: "BlogHome",
  },
  {
    path: "/blog/posts/:id",
    name: "BlogPost",
  },
  {
    path: "/blog/categories/:id",
    name: "BlogCategory",
  },
];

export const blogRoutes = routes.map<*>(createUniversalRoute);
