import styled from "styled-components";
import { componentFromProp, defaultProps } from "recompose";
import { nonSelectable } from "../../styles";
import { marginHelpers } from "../../styles/positioning";

import buttonStyles from "./buttonStyles";

const Button = defaultProps({ as: "button" })(componentFromProp("as"));

const StyledButton = styled(Button)`
  ${nonSelectable}
  ${buttonStyles}
  ${marginHelpers}
`;

StyledButton.displayName = "Button";

// eslint-disable-next-line
export default StyledButton;
