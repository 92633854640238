// @flow
import { Component } from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { ServerError } from "../pages/ServerError";

type Props = {
  error: Error,
};

class UniversalComponentError extends Component<Props> {
  componentDidMount() {
    const { error } = this.props;
    Sentry.captureException(error);
  }

  render() {
    return <ServerError />;
  }
}

export { UniversalComponentError };

// eslint-disable-next-line import/no-default-export
export default UniversalComponentError;
