import { branch, renderComponent } from "recompose";
import Button from "./Button";
import ButtonWithWrapper from "./ButtonWithWrapper";

const styledComponent = (isCustomComponent) =>
  branch(isCustomComponent, renderComponent(ButtonWithWrapper));

export const enhance = styledComponent(({ as }) => typeof as === "function");
Button.displayName = "Button";

// eslint-disable-next-line
export default enhance(Button);
