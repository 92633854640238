const theme = {
  border: {
    width: "2px",
    radius: "4px",
  },
  colors: {
    navy: "hsl(219,21%,26%)",
    light: "hsl(219,21%,40%)",
    peachy: "hsl(20,100%,86%)",
    honey: "hsl(37,100%,77%)",
    white: "hsl(0,0%,100%)",
    blue: "hsl(212,100%,92%)",
    mint: "hsl(165,48%,84%)",
    primary: {
      default: "#9df6df",
      light: "#e3fcf6",
      lighter: "#f5fffc",
      dark: "#57efc7",
    },
    secondary: {
      default: "#167cef",
      light: "#5da3f4",
      lighter: "#eef4fc",
      dark: "#0c56ac",
    },
    tertiary: {
      default: "#269b7c",
      light: "#3ed0a9",
      dark: "#175e4b",
    },
    greys: {
      default: "#555555",
      light: "#eeeeee",
      lighter: "#f5f5f5",
      dark: "#222222",
      white: "#ffffff",
      mid: "#999999",
    },
    error: {
      default: "#dc2c2c",
      light: "#e76e6e",
      dark: "#a21a1a",
    },
    background: {
      default: "#ffffff",
      primary: "#e5fff7",
      secondary: "#f3f8fe",
    },
  },
  containerSizes: {
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1200px",
  },
  font: {
    family: '"Euclid Circular B", sans-serif',
    light: "300",
    bold: "500",
    size: {
      default: "18px",
    },
    lineHeight: {
      default: "28px",
    },
    typographyBreakpoint: "660px",
  },
  form: {
    element: {
      height: {
        small: "40px",
        default: "48px",
      },
      width: {
        default: "400px",
      },
    },
  },
  icon: {
    sm: 24,
  },
  mediaBreakpoints: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1240px",
  },
  newMediaBreakpoints: {
    sm: "320px",
    md: "720px",
    lg: "1025px",
  },
  padding: {
    base: "10px",
    small: "20px",
    medium: "20px",
    large: "50px",
  },
};

// eslint-disable-next-line
export default theme;
