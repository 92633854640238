import styled from "styled-components";
import { fontStyle } from "../../../styles/text";

const H1 = styled.h1`
  ${({ theme }) =>
    fontStyle({
      fontWeight: theme.font.bold,
      smallFontSize: "48px",
      smallLineHeight: "56px",
      mediumFontSize: "64px",
      mediumLineHeight: "64px",
    })};
`;

H1.displayName = "H1";

// eslint-disable-next-line
export default H1;
