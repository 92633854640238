import { call, put, select, takeEvery } from "redux-saga/effects";

import { fetchByUid } from "./fetchByUid";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function refSelector(state) {
  return state.cms.version.ref;
}

export function* fetchData(action) {
  const {
    payload: {
      template,
      uid = template,
      fetchFunction = fetchByUid,
      successCallback,
      errorCallback,
      emptyCallback = successCallback,
    },
    attempt = 1,
  } = action;

  try {
    const ref = yield select(refSelector);

    const json = yield call(fetchFunction, {
      template,
      uid,
      ref,
    });
    const callback = json === null ? emptyCallback : successCallback;

    yield put(callback(json, uid));
  } catch (e) {
    // If failed to fetch for whatever reason we want to retry 2 more times
    if (attempt < 3) {
      yield call(delay, 200);

      yield* fetchData({ ...action, attempt: attempt + 1 });
    } else {
      yield put(errorCallback(e));
    }
  }
}

export function isCmsFetch(action) {
  return action.fetchData === true;
}

// eslint-disable-next-line
export default function* fetchDataListener() {
  yield takeEvery(isCmsFetch, fetchData);
}

export { getRef } from "./getRef";
export { fetchByUid } from "./fetchByUid";
export { fetchByType } from "./fetchByType";
