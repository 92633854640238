import cmsUrl from "../helpers/cmsUrl";
import getRandomVariation from "./getRandomVariation";

export async function getRef(cms, storedVersion = {}) {
  try {
    const prismicResponse = await cms.networkLayer(cmsUrl({ cms }));
    const experiment = prismicResponse.experiments.running[0];

    if (experiment === undefined) {
      return {
        experimentId: null,
        variationId: null,
        variation: null,
        ref: prismicResponse.refs[0].ref,
        experiment,
      };
    }

    if (
      !storedVersion.experimentId ||
      storedVersion.experimentId !== experiment.googleId
    ) {
      return getRandomVariation(experiment);
    }

    const variationIndex = experiment.variations.findIndex(
      ({ id }) => id === storedVersion.variationId,
    );

    if (variationIndex !== -1) {
      return {
        experimentId: experiment.googleId,
        variationId: experiment.variations[variationIndex].id,
        variation: variationIndex,
        ref: experiment.variations[variationIndex].ref,
        experiment,
      };
    }
    return getRandomVariation(experiment);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error while fetching master information from CMS");
    // eslint-disable-next-line no-console
    console.error(error);
    return {};
  }
}
