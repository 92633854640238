// @flow
import { ApolloClient } from "@apollo/client";

import { App } from "./containers/App/App";
/*
 * Global styles should be injected before any other scoped style, so make sure
 * this file is imported before any styled component.
 */
import { Providers } from "./Providers";

type Props = {
  store: ReduxStore,
  client?: ApolloClient<any>,
};

function Main({ client, store }: Props) {
  return (
    <Providers client={client} store={store}>
      <App />
    </Providers>
  );
}

export { Main };
