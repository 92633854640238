import styled from "styled-components";

import { marginHelpers } from "../../styles/positioning";

import buttonStyles from "./buttonStyles";

const Wrapper = styled.span`
  a,
  button {
    ${buttonStyles};
  }
  ${marginHelpers};
`;

const ButtonWithWrapper = ({
  as: Component,
  children,
  // These are de-structured to omit from passing them on to component
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  primary,
  secondary,
  wrapperProps = {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    primary,
    secondary,
  },
  ...rest
}) => (
  <Wrapper {...wrapperProps} {...rest}>
    <Component {...rest}>{children}</Component>
  </Wrapper>
);

ButtonWithWrapper.displayName = "Button";

// eslint-disable-next-line
export default ButtonWithWrapper;
