import { css } from "styled-components";

import theme from "../themes/theme";

// iterate through the sizes and create a media template
const media = Object.keys(theme.mediaBreakpoints).reduce(
  (accumulator, label) => {
    const mediaQuery = (arg, ...args) => css`
      @media (min-width: ${theme.mediaBreakpoints[label]}) {
        ${css(arg, ...args)};
      }
    `;
    return { ...accumulator, [label]: mediaQuery };
  },
  {},
);

export const containerCss = css`
  ${({ fixed, theme: { padding, containerSizes } }) =>
    fixed
      ? css`
          padding-left: ${padding.base};
          padding-right: ${padding.base};
          margin: 0 auto;

          ${media.sm`
      padding-left: 0;
      padding-right: 0;
      width: ${containerSizes.sm};
    `} ${media.md`
      width: ${containerSizes.md};
    `} ${media.lg`
      width: ${containerSizes.lg};
    `} ${media.xl`
      width: ${containerSizes.xl};
    `};
        `
      : ""};
`;

// eslint-disable-next-line
export default media;
