// @flow
import Cookies from "universal-cookie";

/* The _ga cookie consists of the letters GA followed by four numbers split by dots.
   The first two are irrelevant version numbers, and the last two are combined to
   make the GA client ID. */
const GA_COOKIE_REGEX = /^GA\d+\.\d+\.(?<id>\d+\.\d+)$/;

const getFromDataLayer = () => {
  const { dataLayer } = window;
  const googleAnalyticsLoadEvent =
    (dataLayer && dataLayer.find((event) => Boolean(event.cid))) || {};

  return googleAnalyticsLoadEvent.cid;
};

const getFromCookie = () => {
  const cookies = new Cookies();
  const gaCookie = cookies.get("_ga");
  const match = GA_COOKIE_REGEX.exec(gaCookie);
  return match?.groups?.id;
};

export const getAnalyticsMetadata = () => {
  const gaClientId = getFromDataLayer() || getFromCookie() || null;
  if (gaClientId) {
    return { gaClientId };
  }

  return {};
};
